import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";

import Template from '../templates/template';
import BlockArticle from '../components/blocks/block-article';

const PageArticles = inject("stores") (
    observer (
        class PageArticles extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;      

                this.state = {
                    items: [],
                    block1: []
                };
            }

            componentDidMount = async() => {
                this.currentLang = this.props.stores.storeUi.app_interface.lang;

                let idx;
                // idx = 63; //NL DEV
                // idx = 172; //EN DEV
                // idx = 33 //NL
                idx = 65; //EN
                const block1 = await this.storeContent.getContentBlock('pages', idx);
                const items = await this.storeContent.getContentPages('articles', 100);

                this.setState({
                    items: items,
                    block1: block1
                })
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <Template>
                        <div className="page-content page-content--articles">
                            <div
                                className="page-content--articles__leader"
                            >
                                <div
                                    className="page-content--articles__intro"
                                >
                                    {this.state.block1 && this.state.block1.content &&
                                        <h1 
                                            className="content__header" 
                                            dangerouslySetInnerHTML={{ __html: this.state.block1.title.rendered}}
                                        />
                                    }
                                    {this.state.block1 && this.state.block1.content &&
                                        <div 
                                            className="content__text" 
                                            dangerouslySetInnerHTML={{ __html: this.state.block1.content.rendered}}
                                        />
                                    }
                                </div>
                            </div>                            
                        
                            <div className="page-content--articles__blocks blocks--articles">
                                {this.state.items.map((item) => (
                                    <BlockArticle
                                        item = {item}
                                    />
                                ))}
                            </div>
                        </div>
                    </Template>
                );
            }
        }
    )
);

export default PageArticles;