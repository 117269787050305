import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const Branding = inject("stores") (
    observer (
        class Branding extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;

                this.state = {};
            }

            componentDidMount = async() => {
            }

            componentDidUpdate = async(prevProps) => {

            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper--branding">
                            <div className="branding__logo">
                                <img src={this.urlAPI + "/public/images/branding/logo-bastimmer-white-txt.png"} />
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default Branding;