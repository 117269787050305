import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import LanguageSelector from '../general/language-selector';

const NavigationLanguages = inject("stores") (
    observer (
        class NavigationLanguages extends Component {

            constructor(props) {
                super(props);
                this.storeUi = this.props.stores.storeUi;
                this.storeNavigation = this.props.stores.storeNavigation;
            }

            componentDidMount = async () => {
            }

            componentDidUpdate = async (prevProps) => {
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            render() {
                return (
                    <div className="wrapper--navigation-languages">
                        <LanguageSelector
                        />
                    </div>
                )
            }
        }
    )
)

export default withRouter(NavigationLanguages);

