import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

const BlockWorkshops = inject("stores") (
    observer (
        class BlockWorkshops extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    items: []
                };
            }

            componentDidMount = async() => {
            }

            componentDidUpdate = async(prevProps) => {
                let items;
                if(prevProps.items !== this.props.items){
                    items = this.props.items;
                    this.setState({
                        items: items
                    })
                }

            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('workshops');
                const url = '/content/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper--blocks">
                            WORKSHOPS
                            <ul>
                                {this.state.items.map((workshop) => (
                                    workshop.language === this.storeUi.lang &&
                                        <li>
                                            <h2 
                                                className = "content__header --link" 
                                                dangerouslySetInnerHTML = {{ __html: workshop.title.rendered}}
                                                onClick = {() => this.handleNav(workshop)}
                                            />
                                        </li>
                                ))}
                            </ul>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockWorkshops);