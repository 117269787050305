import { makeAutoObservable, makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as content from '../communicator/content';
import * as pages from '../communicator/pages';
import * as menus from '../communicator/menus';

class StoreNavigation {
    languages = [
        {
            id: 1,
            sku: 'nl_NL',
            displayValue: 'NL',
            locale: 'nl'
        },{
            id: 2,
            sku: 'en_UK',
            displayValue: 'EN',
            locale: 'en'
        }
    ]

    utilities = [
        {
            id: 1,
            sku: 'TERMS',
            displayValue: 'Terms of Service',
            i18n: 'terms'
        },{
            id: 2,
            sku: 'PRIVACY',
            displayValue: 'Privacy Statement',
            i18n: 'privacy'
        }
    ]

    menusAll = []
    menuMain = []

    externalSites = [
        {
            id: 1,
            sku: 'SHELTERLAB',
            displayValue: 'Shelterlab',
            url: 'https://www.shelterlab.world',
            displayUrl: 'shelterlab.world'
        },{
            id: 2,
            sku: 'SHELTERSUIT',
            displayValue: 'Sheltersuit Foundation',
            url: 'https://sheltersuit.com',
            displayUrl: 'sheltersuit.com'
        }
    ]

    idCurrentPage = '';

    constructor() {
        makeObservable(this, {
            idCurrentPage: observable,
            menusAll: observable,
            menuMain: observable
        })
        this.getMenus();
    }

    async getMenus() {
        let content = {};
        try {
            const returnData = await menus.getMenus();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    console.log('SbC menus', returnData)
                    this.menusAll = returnData;
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getMenu(id) {
        console.log('SbC menu main..')

        let menu;
        try {
            const returnData = await menus.getMenu(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.menuMain = returnData.items;
                    console.log('SbC menu main', returnData.items)
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error";
                console.log('SbC error api return menu')
            })
        }
    }

    setCurrentPage = (id) =>{
        this.idCurrentPage = id;
    }
}

export default StoreNavigation;