import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import Typewriter from 'typewriter-effect';

const BlockTypewriter = inject("stores") (
    observer (
        class BlockTypewriter extends Component {

            constructor(props) {
                super(props);

                this.typingText1 = "ALWAYS ONDE";
                this.typingText2 = "UNDER CONSTRUCTION";

                this.state = {
                   
                };
            }

            componentDidMount = async() => {
            }

            componentDidUpdate = async(prevProps) => {
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper-typewriter">
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter
                                        .changeDelay(200)
                                        .pauseFor(500)
                                        .typeString(this.typingText1)
                                        .pauseFor(300)
                                        .deleteChars(4)
                                        .typeString(this.typingText2)
                                        .pauseFor(2500)
                                        .deleteAll()
                                        .start();
                                }}
                                options={{
                                    autoStart: true,
                                    loop: true,
                                 }}
                            />
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockTypewriter);