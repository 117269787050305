import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";

import * as ui from '../utilities/ui';
import Template from '../templates/template';

const PageCollection = inject("stores") (
    observer (
        class PageCollection extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  
                this.storeCollections = this.props.stores.storeCollections;  

                this.state = {
                   
                } 
            }

            componentDidMount = async() => {
                this.currentLang = this.props.stores.storeUi.app_interface.lang;

                ui.handleScroll('top');
                let slug = '';
                let route = '';

                if (this.storeContent.content_pages.length === 0) {
                    let pages = await this.storeContent.getContentPages('pages', 100);
                }

                //const page =  this.storeContent.content_pages.find(page => page.slug === slug);

                let idx;
                if (this.storeUi.lang === 'nl') {
                    idx = 73;
                }
                if (this.storeUi.lang === 'en') {
                    idx = 9;
                }               

                let content = await this.storeContent.getContentPage(idx);

                let seo = content.yoast_head_json;
                let acf = content.acf;

                this.setState({
                    route: route,
                    content: content,
                    slug: slug,
                    acf: acf,
                    seo: seo
                })
                
            }

            componentDidUpdate = async(prevProps) => {
                let idx;

                if (prevProps.lang !== this.props.lang) {
                    if (this.storeUi.lang === 'nl') {
                        idx = 73;
                    }
                    if (this.storeUi.lang === 'en') {
                        idx = 9;
                    }
                    let content = await this.storeContent.getContentPage(idx);
                    
                    this.setState({
                        content: content
                    })
                }
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <Template>
                        <div className="page-content page-content--collection">
                            <div
                                className="page-content--collection__leader"
                            >
                                <div
                                    className="page-content--collection__intro"
                                >
                                    {this.state.content && this.state.content.content &&
                                        <h1 
                                            className="content__header" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.title.rendered}}
                                        />
                                    }

                                    {this.state.content && this.state.content.content &&
                                        <div 
                                            className="content__text" 
                                            dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}}
                                        />
                                    }
                                </div>
                                <div
                                    className="page-content--collection__img--intro"
                                    style= {{backgroundImage:`url(${this.urlAPI + "public/images/collections/aw22/collection-aw22-leader.png"})` }}
                                >
                                </div>
                            </div>                            
                        
                            <div className="page-content--collection__blocks blocks--collection">
                                {this.storeCollections.collections.aw22.map((item) => (
                                    <div
                                        className="block--collection"
                                    >
                                        <div
                                            className="block--collection__img"
                                        >
                                            <img 
                                                src={this.urlAPI + "public/images/collections/aw22/" + item.img} 
                                            />
                                        </div>
                                        <span 
                                            className="block--collection__caption"
                                        >
                                            {item.caption}
                                            <br/>
                                            <span 
                                                className="block--collection__price"
                                            >
                                                {item.price}
                                            </span>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Template>
                );
            }
        }
    )
);

export default PageCollection;