import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";

import Template from '../templates/template';
import LanguageSelector from '../components/general/language-selector';

const SettingLanguage = inject("stores") (
    observer (
        class SettingLanguage extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;   
            }

            componentDidMount() {
                this.currentLang = this.props.stores.storeUi.app_interface.lang;
            }

            goBack = () => {
                this.props.history.goBack();
            }

            handleCancel = (e, lang) => {
                this.storeUi.setLocale(lang);
                this.goBack();
             }

            handleSubmit = (e) => {
               this.goBack();
            }
        
            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <div className="wrapper-language">
                        <div className="container-language">
                            <div className="container-language__header">
                                <h1>{i18n.t("dialog.header.setting_language")}</h1>
                                <p>{i18n.t("dialog.content.setting_language")}</p>
                            </div>

                            <div className="container-language__content">
                                <LanguageSelector
                                />
                            </div>

                            <div className="container-language__footer">
                                <div className="button-row">
                                    <span className="button button--language button--secundary" onClick={(e) => this.handleCancel(e, this.currentLang)}>
                                        {i18n.t("button.cancel")}
                                    </span>
                                    <span className="button button--language button--primary" onClick={(e) => this.handleSubmit(e)}>
                                        {i18n.t("button.apply")}
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);

export default SettingLanguage;