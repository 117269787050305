import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../utilities/withRouter';
import i18n from "i18next";

import * as ui from '../utilities/ui';
import Template from '../templates/template';

const Start = inject("stores") (
    observer (
        class Start extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                // this.env = process.env.REACT_APP_ENV;
                // this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  

                this.state = {
                    content: [],
                    content2: []
                }; 
            }

            componentDidMount = async() => {
                let key;
                if (this.storeUi.lang === 'nl') {
                    key = 53;
                }
                if (this.storeUi.lang === 'en') {
                    key = 51;
                }
                const content = await this.storeContent.getContentBlock('pages', key);
                //console.log('SbC content', key, content)

                // const content2 = await this.storeContent.getContentPages('workshops', 100);
                // console.log('SbC content wokshops', content2)

                this.setState({
                    content: content
                })
            }

            componentDidUpdate = async(prevProps) => {
                let key;

                if (prevProps.lang !== this.props.lang) {
                    if (this.storeUi.lang === 'nl') {
                        key = 53;
                    }
                    if (this.storeUi.lang === 'en') {
                        key = 51;
                    }
                    const content = await this.storeContent.getContentBlock('pages', key);
                    console.log('SbC content', key, content)

                    this.setState({
                        content: content
                    })
                }
            }

            gotoBlock = () => {
                //ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <Template
                        bg = {true}
                    >
                        <div>
                            {/* {this.state.content && this.state.content.title &&
                            <h1 
                                className="content__header" 
                                dangerouslySetInnerHTML={{ __html: this.state.content.title.rendered}}
                            />
                            }
                            {this.state.content && this.state.content.content &&
                            <div 
                                className="content__text" 
                                dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}}
                            />
                            } */}

                            {/* <div>
                                <BlockWorkshops
                                    items = {this.state.content2}
                                />
                            </div> */}
                        </div>
                    </Template>
                );
            }
        }
    )
)

export default Start;
