import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';

const BlockArticle = inject("stores") (
    observer (
        class BlockArticle extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                };
            }

            componentDidMount = async() => {
                const featuredImage = this.props.item.better_featured_image.media_details.sizes.medium.source_url;

                this.setState({
                    featuredImage: featuredImage
                })
            }

            componentDidUpdate = async(prevProps) => {
                let items;
                if(prevProps.items !== this.props.items){
                    items = this.props.items;
                    this.setState({
                        items: items
                    })
                }

            }

            handleNav = (item) => {
                const idx = item.id;
                this.storeNavigation.setCurrentPage(idx);
                this.storeContent.setCurrentPageType('articles');
                const url = '/content/' + item.title.rendered.toLowerCase();
                this.props.navigate(url);
            }

            gotoBlog = (blog, slug) => {
                this.storeContent.setCurrentPage(blog);

                let url = "/blog/" + slug;
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper--block">
                            <div 
                                className="block--article --link"
                                onClick = {(e) => this.handleNav(this.props.item)}
                            >
                                <div className="block--article__img">
                                    <img src={this.state.featuredImage} />
                                </div>
                                <span 
                                    className="block--article__title"
                                    dangerouslySetInnerHTML={{ __html: this.props.item.title.rendered}}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockArticle);