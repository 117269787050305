import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Helmet } from 'react-helmet';

import AppRoutes from './routes';

const App = inject("stores") (
    observer (
        class App extends Component {
            constructor(props) {
                super(props);
                this.props = props;
            }
         
            render() {
                return (
                    <div className="app">
                        {/* <Helmet>
                            <title>Shelter-Lab</title>
                            <meta name="description" content="People helping people" />
                        </Helmet> */}

                        <AppRoutes />
                    </div>
                );
            }
        }
    )
)

export default App;
