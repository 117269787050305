import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";

import Template from '../templates/template';
import FormUnderConstruction from '../components/forms/form-under-construction';
import BlockTypewriter from '../components/blocks/block-typewriter';

const UnderConstruction = inject("stores") (
    observer (
        class UnderConstruction extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;   
            }

            componentDidMount() {
                this.currentLang = this.props.stores.storeUi.app_interface.lang;
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <div className="wrapper--underconstruction">
                        <div className="wrapper--underconstruction__branding">
                            <img 
                                src="https://www.bastimmer.com/api/public/images/branding/logo-bastimmer-white-on-black.png"  
                            />
                        </div>
                        <div className="wrapper--underconstruction__form">
                            <FormUnderConstruction
                            
                            />
                        </div>
                        <div className="wrapper--underconstruction__content">
                            <BlockTypewriter

                            />
                        </div>
                    </div>                        
                );
            }
        }
    )
);

export default UnderConstruction;