import React, { PureComponent } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import i18n from "i18next";

const NavigationMain = inject("stores") (
    observer (
        class NavigationMain extends PureComponent {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;
            }

            componentDidMount = async () => {
                let menu;
                if (this.props.lang === 'nl') {
                    menu = await this.storeNavigation.getMenu('menumainnl');
                } else {
                    menu = await this.storeNavigation.getMenu('menumainen');
                }
            }

            componentDidUpdate = async (prevProps) => {
                let menu;
                if (prevProps.lang !== this.props.lang) {
                    if (this.storeUi.lang === 'nl') {
                        menu = await this.storeNavigation.getMenu('menumainnl');
                    } else {
                        menu = await this.storeNavigation.getMenu('menumainen');
                    }
                }
            }

            handleHome = () => {
                this.storeNavigation.setCurrentPage(0);
                const url = '/start';
                this.props.navigate(url);
            }

            handleNav = (item) => {
                let url;
                const idx = item.object_id;
                this.storeNavigation.setCurrentPage(idx);

                if (idx === '19' || idx === '25') {
                    this.storeContent.setCurrentPageType('workshops');
                } else {
                    this.storeContent.setCurrentPageType('pages');
                }
                
                switch(item.object_id) {
                    case '9':
                        url = '/collection';
                    break;
                    case '65':
                        url = '/articles';
                    break;
                    default:
                        url = '/content/' + item.title.toLowerCase();
                }
                this.props.navigate(url);
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);

                return (
                    <React.Fragment>
                            <div 
                                className="navigation__column --link"
                                onClick = {() => this.handleHome()}
                            >
                                HOME
                            </div>
                            {this.storeNavigation.menuMain.map((menuitem) => (
                                <div 
                                    className = "navigation__column --link"
                                    onClick = {() => this.handleNav(menuitem)}
                                >
                                    {menuitem.title}
                                </div>
                            ))}
                       
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(NavigationMain);

