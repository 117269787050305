import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import './assets/css/bastimmer.css';

import StoreRoot from './stores/store-root';

const startApp = () => {
    const storeRoot = new StoreRoot();
    const root = ReactDOM.createRoot(document.getElementById('root'));
    //const rootEl = document.getElementById("vuurwerk");
    //const settings = rootEl.dataset.settings;

    root.render(
        <React.StrictMode>
            <I18nextProvider i18n={i18n}>
                <Provider stores={storeRoot}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>
            </I18nextProvider>
        </React.StrictMode>
    );
}

if (!window.cordova) {
    startApp()
} else {
    document.addEventListener('deviceready', startApp, false)
}
