import * as apifetch from './fetch.js';

export async function sendFormContact(form, data) {
    //SbC cant be right .... should be
    // const urlAPI = process.env.REACT_APP_URL_API;
    // const urlEndpoint = urlAPI + 'email/contact';
    const urlEndpoint = 'email/contact';

    const postData = data;

    return apifetch.btFetch(urlEndpoint, 'POST', postData);
}

export async function sendFormUnderConstruction(form, data) {
    //SbC cant be right .... should be
    // const urlAPI = process.env.REACT_APP_URL_API;
    // const urlEndpoint = urlAPI + 'email'+ form;
    const urlEndpoint = 'email/'+ form;

    const postData = data;

    console.log('SbC', urlEndpoint, postData)

    return apifetch.btFetch(urlEndpoint, 'POST', postData);
}