import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import RowText from '../form-rows/row-text';

const FormUnderConstruction = inject("stores") (
    observer (
        class FormUnderConstruction extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formControls: {  
                        email: {
                            type: "email",
                            label: i18n.t("forms.label.email"),
                            value: '',
                            validationRules: {
                                isRequired: true, 
                                isEmail: true
                            },
                            valid: false
                        }
                    },
                    formIsValid: false,
                    formIsSubmitted: false,
                    btn_class: '--disabled',
                    error: false,
                    feedback: false
                }
            }

            componentWillUnmount = () => {
                //this.storeUi.clearFormFeedback();
            }

            componentDidMount = () => {
                this.initForm();
            }

            initForm = () => {
                
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    feedback: false,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            sendFormUnderConstruction = (e) => {
                e.preventDefault();

                if (this.state.formIsValid) {
                    this.storeForms.sendFormUnderConstruction(this.state.formControls);
                    this.afterSubmit('disable');
                }
            }

            afterSubmit = (action) => {  
                if (action === 'disable') {
                    this.setState({
                        formIsValid: false,
                        formIsSubmitted: true,
                        btn_class: '--disabled',
                        feedback: true
                    }); 
                }
            } 

            render() {
                return (
                    <div>
                        <form className="form form--subscribe">
                            {Object.keys(this.state.formControls).map((key, i) => (
                                (this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email") && 
                                    key !== "calculation" &&
                                        <RowText 
                                            type = {this.state.formControls[key].type}
                                            name = {key}
                                            label = {this.state.formControls[key].label}
                                            value = {this.state.formControls[key].value}
                                            validationRules = {this.state.formControls[key].validationRules}
                                            onValidate = {this.validateRow}
                                            style = {'inverted'}
                                            lng = {this.storeUi.app_interface.lang}
                                            displayLabel = 'off'
                                        />                                       
                            ))}   
                                                  
                            <div className="form-row form-row--buttons --subscribe">
                                <button 
                                    className={"btn btn--primary " + this.state.btn_class} 
                                    onClick={(e) => this.sendFormUnderConstruction(e)}>
                                        Sign me up
                                </button>
                            </div>
                        </form>
                       
                        {this.state.feedback &&
                            <div 
                                className="form-row form-row--feedback"
                                dangerouslySetInnerHTML={{ __html: i18n.t("forms.feedback.success")}} 
                            />
                        }
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FormUnderConstruction);
