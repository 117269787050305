import * as apifetch from './fetch.js';

export async function getContentPage(id) {
    const urlAPI = process.env.REACT_APP_URL_API_WP;
    const urlEndpoint = urlAPI + 'pages/' + id;

    return apifetch.btFetch(urlEndpoint, 'GET');
}

export async function getContentPages(section, amount) {
    const urlAPI = process.env.REACT_APP_URL_API_WP;
    const urlEndpoint = urlAPI + section + '/?per_page=' + amount;

    return apifetch.btFetch(urlEndpoint, 'GET');
}

export async function getCustomFields(id) {
    const urlAPI = process.env.REACT_APP_URL_API_WPACF;
    const urlEndpoint = urlAPI + 'pages/' + id;

    return apifetch.btFetch(urlEndpoint, 'GET');
}
