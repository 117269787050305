import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import {withRouter} from '../utilities/withRouter';
import i18n from "i18next";
// import MetaTags from 'react-meta-tags';

import * as ui from '../utilities/ui';
import Template from '../templates/template';
import BlockWorkshops from '../components/blocks/block-workshops';

const ContentPage = inject("stores") (
    observer (
        class ContentPage extends React.Component {

            constructor(props) {
                super(props);
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    slug: '',
                    pageblocks: []
                };

                //this.blockRef = React.createRef();
            }

            componentDidMount = async () => {
                ui.handleScroll('top');

                const idx= this.storeNavigation.idCurrentPage;
                const content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                const featuredImage = content.better_featured_image.media_details.sizes.medium.source_url;

                this.setState({
                    content: content,
                    featuredImage: featuredImage
                })
            }

            componentDidUpdate = async (prevProps) => {
                let content;
                let content2;

                if (prevProps.idx !== this.props.idx) {
                    content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, this.props.idx);

                    this.setState({
                        content: content
                    })
                }

                let lang;
                if (prevProps.lang !== this.props.lang) {
                    if (this.props.lang === 'nl') {
                        lang = "nl_NL";
                    }
                    if (this.props.lang === 'en') {
                        lang = "en_GB";
                    }
                    const langs = this.state.content.polylang_translations;
                    const obj = langs.find(o => o.locale === lang);
                    const idx = obj.id;
                    content = await this.storeContent.getContentBlock(this.storeContent.currentPageType, idx);

                    this.setState({
                        content: content
                    })
                }
            }

            gotoBlock = () => {
                ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            render() {
                return (
                    <Template>
                        <div className="page-content page-content--article">
                            <div
                                className="page-content__columns"
                            >
                                <div
                                    className="page-content__column page-content__column--leader"
                                >
                                    <div
                                        className="page-content--article__leader"
                                    >
                                        <div
                                            className="page-content--article__intro"
                                        >
                                            {this.state.content && this.state.content.content &&
                                                <h1 
                                                    className="content__header" 
                                                    dangerouslySetInnerHTML={{ __html: this.state.content.title.rendered}}
                                                />
                                            }
                                            
                                        </div>
                                    </div>  
                                </div>

                                <div
                                    className="page-content__column page-content__column--featured-image"
                                >
                                    <img src={this.state.featuredImage} /> 
                                </div>

                                <div
                                    className="page-content__column page-content__column--content"
                                >
                                    
                                            {this.state.content && this.state.content.content &&
                                                <div 
                                                    className="content__text" 
                                                    dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}}
                                                />
                                            }
                                     
                                </div>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withRouter(ContentPage);

