import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";

import Template from '../templates/template';

const PageVision = inject("stores") (
    observer (
        class PageVision extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;   
            }

            componentDidMount() {
                this.currentLang = this.props.stores.storeUi.app_interface.lang;
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <Template>
                        <div className="wrapper--page">
                        PageVision
                        </div>
                    </Template>
                );
            }
        }
    )
);

export default PageVision;