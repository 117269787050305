import { makeAutoObservable, makeObservable, observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';

class StoreCollections {
    collections = {
        aw22: [
            {
                sku: 1234,
                img: "Sheltersuitmodellen3_750x.png",
                caption: "CHANGE THE WORLD TEE NAVY + SHELTERBAG ",
                price: 155
            },{
                sku: 1234,
                img: "SheltersuitModellen10_750x.png",
                caption: "xxCHANGE THE WORLD TEE LIGHT + SHELTERBAG",
                price: 155
            },{
                sku: 1234,
                img: "SheltersuitModellen22_750x.png",
                caption: "CHANGE THE WORLD TEE GREEN + SHELTERBAG",
                price: 155
            },{
                sku: 1234,
                img: "SheltersuitModellen54_5008f6e6-197f-4b16-b85e-5f1f6ed94509_750x.png",
                caption: "CARGO PANTS DENIM + SHELTERBAG",
                price: 450
            },{
                sku: 1234,
                img: "SheltersuitModellen183_750x.png",
                caption: "CARGO PANTS CORDUROY + SHELTERBAG",
                price: 450
            }

        ]
    }
    
    constructor() {
        makeObservable(this, {
            collections: observable
        })
    }
    
   
}

export default StoreCollections;