import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const SectionSocial = inject("stores") (
    observer (
        class SectionSocial extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeNavigation = this.props.stores.storeNavigation;

                this.state = {
                    
                };
            }

            componentDidMount = async() => {
               
            }

            componentDidUpdate = async(prevProps) => {

            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="wrapper--social">
                            <ul className="social-icons">
                                <li className="social-icon">
                                    <img 
                                        src={this.urlAPI + "/public/images/icons/ic-instagram.png"} 
                                    />
                                </li>
                                <li className="social-icon">
                                    <img 
                                        src={this.urlAPI + "/public/images/icons/ic-linkedin.png"} 
                                    />
                                </li>
                            </ul>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default SectionSocial;