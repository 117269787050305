import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Navigation from '../components/header/navigation';
import Branding from '../components/header/branding';
import NavigationFooter from '../components/footer/navigation';
import SectionSocial from '../components/footer/section-social';

const Template = inject("stores") (
    observer (
        class Template extends Component {

            constructor(props) {
                super(props);
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;

                this.state = {
                };
            }

            componentDidMount = async() => {
            }

            componentDidUpdate = async(prevProps) => {
            }
      
            render() {
                return (
                    <React.Fragment>
                        {this.props.bg && 
                            <div className="wrapper--background" />
                        }
                        <div className="wrapper--page">
                            <div className="wrapper--header">
                                <Branding />
                                <Navigation />
                            </div>

                            {this.props.children}
                            
                        </div>
                        <div className="wrapper--footer">
                            <SectionSocial

                            />
                            <NavigationFooter 
                            
                            />                        
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default Template;
